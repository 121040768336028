// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
window.jQuery = $;
window.$ = $;
require("flatpickr")
// require("chartkick")
// require("chart.js")
require("trix")
require("@rails/actiontext")
import 'bootstrap';
import './src/application.scss';
import './src/navbar.js';
import './src/jquery.cardswipe.js'
import './src/feather.css';
import './src/theme.css';
import './src/retain.scss';
import './src/course_editor.scss';
import './src/survey.jquery.min.js';
import './src/actiontext.scss';
import moment from 'moment';
import 'select2';
import 'select2/dist/css/select2.css';
import 'bootstrap-select';
import 'bootstrap-select/dist/css/bootstrap-select.min.css';
import 'list.js/dist/list.min.js';
import '@client-side-validations/client-side-validations';
import 'jquery-mask-plugin/dist/jquery.mask.min.js';
import 'pretty-checkbox/dist/pretty-checkbox.min.css';
import * as Toastr from 'toastr';
import * as Survey from 'survey-jquery';
import * as ClipboardJS from 'clipboard';
import 'toastr/build/toastr.css';
import "chartkick/chart.js";
import { Chart } from 'chart.js';
import { WordCloudController, WordElement } from 'chartjs-chart-wordcloud';
import { Calendar } from '@fullcalendar/core';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import momentPlugin from '@fullcalendar/moment';

'use strict';

window.initMap = function(...args) {
  const event = document.createEvent("Events")
  event.initEvent("google-maps-callback", true, true)
  event.args = args
  window.dispatchEvent(event)
}

window.addEventListener('turbolinks:before-render', function () {
  window.zEACLoaded = undefined;
});

function appointmentTeamCalendar() {
  var calendarEl = document.getElementById('teamCalendar');
  if (calendarEl) {
    var calendar = new Calendar(calendarEl, {
      plugins: [ dayGridPlugin, timeGridPlugin, listPlugin, momentPlugin ],
      eventDisplay: 'block',
      events: '/appointments/team_calendar.json' + window.location.search,
      slotMinTime: "05:00:00",
      slotMaxTime: "22:00:00",
      initialView: 'timeGridWeek',
      aspectRatio: 1.5,
      dayMaxEventRows: true,
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
      },
      allDaySlot: false,
      loading: function( isLoading, view ) {
          if(isLoading) {// isLoading gives boolean value
            $('#calendarSpinner').show();
          } else {
            $('#calendarSpinner').hide();
          }
      },
      eventClick: function(info) {
        $('#calendarSpinner').show();
        Rails.ajax({
          url: `/appointments/${info.event.id}.js?redirect=${encodeURIComponent(window.location.href)}`,
          type: "get",
          success: function(data) {
            $('#calendarSpinner').hide();
          },
          error: function(data) {}
        })
      },
      eventDidMount: function(info) {
        if (info.event.extendedProps.icon) {
          $(info.el).find(".fc-event-title").append("<i class='fe fe-user-x'></i>")
        }
        if (info.event.extendedProps.zoom || info.event.extendedProps.teams) {
          $(info.el).find(".fc-event-title").append(" <i class='fe fe-video'></i>")
        }
        $(info.el).popover(
          {
            title: `${info.event.extendedProps.category} (${info.event.extendedProps.status})`,
            content: `${info.event.extendedProps.details}`,
            html: true,
            placement: "right",
            trigger: 'hover',
            container: 'body'
          }
        );
      },
    });
    calendar.render();
  };
};

function studentScheduler() {
  let appointmentDate = flatpickr($('#student_scheduled_start'), {
    altInput: true,
    altFormat: "F j, Y",
    dateFormat: "Y-m-d"
  });
}

function updateUserAvailability(data) {
  $('#appointmentHosts tr .available-status span').removeClass('badge-soft-success').addClass('badge-soft-secondary');
  $('#appointmentHosts tr .max-hours-remaining').removeClass('over-max-hours')
  $.each(data.available, function (i, val){
    $(`#appointmentHosts tr[id=${val}] .available-status span`).removeClass('badge-soft-secondary');
    $(`#appointmentHosts tr[id=${val}] .available-status span`).addClass('badge-soft-success');
  });
  $.each(data.hours, function (i, val){
    $(`#appointmentHosts tr[id=${val.id}] .max-hours-remaining`).text(val.remaining);
    if (val.remaining < 0) {
      $(`#appointmentHosts tr[id=${val.id}] .max-hours-remaining`).addClass('over-max-hours');
    };
  });
  $('#appointmentHosts tr .available-status .badge-soft-success').parent().parent().prependTo('#appointmentHosts tbody');
}

function magicSchedulerCalendar() {
  var calendarEl = document.getElementById('magicSchedulerCalendar');
  let searchDate = flatpickr($('#appointment_search_start'), {
    altInput: true,
    altFormat: "F j, Y",
    dateFormat: "Y-m-d",
    onChange: function(selectedDates, dateStr, instance) {
      console.log("changed");
      calendar.gotoDate(selectedDates[0]);
    }
  });

  $(document).on('click', '#submitMagicSearch', function(e) {
    e.preventDefault();
    calendar.removeAllEvents();
    var userIds = [];
    var groupIds = [];
    var time_requested = $('#time_requested').val();
    calendar.setOption('slotDuration', time_requested);
    $('#submitMagicSearch').addClass('hide');
    $('#magicSeachLoading').removeClass('hide');
    var student_ids = $('#student_select').select2('data');
    var group_ids = $('#selectGroups').select2('data');
    var term_id = $('#term_id').val();
    var from_date = calendar.view.currentStart;
    var to_date = calendar.view.currentEnd;
    student_ids.forEach(function(selection) {
      userIds.push(selection.id);
    });
    group_ids.forEach(function(selection) {
      groupIds.push(selection.id);
    });
    Rails.ajax({
      url: "/appointments/magic_search.json",
      type: "post",
      data: `user_ids=${userIds}&group_ids=${groupIds}&time_requested=${time_requested}&from_date=${from_date}&to_date=${to_date}&term_id=${term_id}`,
      success: function(data) {
        calendar.addEventSource(data);
        $('#submitMagicSearch').removeClass('hide');
        $('#magicSeachLoading').addClass('hide');
      },
      error: function(data) {}
    })
  });
  if (calendarEl) {
    var calendar = new Calendar(calendarEl, {
      plugins: [ timeGridPlugin, interactionPlugin ],
      eventDisplay: 'block',
      initialView: 'timeGridWeek',
      initialDate: moment().toISOString(),
      selectable: true,
      slotMinTime: "06:00:00",
      slotMaxTime: "21:00:00",
      scrollTime: '08:30:00',
      slotDuration: '01:00:00',
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: ''
      },
      height: 'auto',
      allDaySlot: false,
      datesSet: function(info) {
        console.log(info);
      },
      eventClick: function(info) {
        $('#magicSeachLoading').show();
        console.log(info);
        Rails.ajax({
          url: `/appointments/magic_conflict_view.js?start=${info.event.start}&end=${info.event.end}&conflicts=${info.event.extendedProps.conflictstr}`,
          type: "post",
          success: function(data) {
            $('#magicSeachLoading').hide();
          },
          error: function(data) {}
        })
      },
      selectConstraint: {
        startTime: '06:00', // a start time (10am in this example)
        endTime: '22:00', // an end time (6pm in this example)
      },
    });
    calendar.render();
  };
};

function initSelectLists() {
  var any_checkboxes = $("[name='usersSelect']");
  var allUsers = $("#usersSelectAll");
  var allUsers2 = $("#usersSelectAll2");
  allUsers.on('change', function() {
    var checked;
    checked = $('#usersSelectAll').is(':checked');
    if (checked) {
      var selected_checkboxes = $(this).closest("table").find("[name='usersSelect']");
      selected_checkboxes.prop('checked', 'checked');
      $("#bulkActions").show();
    } else {
      var selected_checkboxes = $(this).closest("table").find("[name='usersSelect']");
      selected_checkboxes.prop('checked', "");
      $("#bulkActions").hide();
    }
  });
  allUsers2.on('change', function() {
    var checked;
    checked = $('#usersSelectAll2').is(':checked');
    if (checked) {
      var selected_checkboxes = $(this).closest("table").find("[name='usersSelect']");
      selected_checkboxes.prop('checked', 'checked');
      $("#bulkActions").show();
    } else {
      var selected_checkboxes = $(this).closest("table").find("[name='usersSelect']");
      selected_checkboxes.prop('checked', "");
      $("#bulkActions").hide();
    }
  });
  any_checkboxes.on('change', function() {
    var anyChecked = $("[name='usersSelect']:checked");
    if (anyChecked.length) {
      $("#bulkActions").show();
    } else {
      $("#bulkActions").hide();
    }
  });
};

window.initSelectLists = initSelectLists;

function initWizard() {
  var toggles = document.querySelectorAll('[data-toggle="wizard"]');
  [].forEach.call(toggles, function(toggle) {
    toggle.addEventListener('click', function(e) {
      e.preventDefault();
      $(toggle).tab('show').removeClass('active');
    });
  });
};

$(document).on('change', '#attendanceToggle #customSwitch1', function(e) {
  $('.custom-label').toggle();
  $('.actuals').toggle();
  if ($(this).prop('checked')) {
    $('#session_note_status').val('completed');
    $('#no_show_warning').hide();
    $('#update_all').val('yes');
    $('#update_all').parent().show();
    $('.sn-required').prop('required', true);
  } else {
    $('#session_note_status').val('missed');
    $('#update_all').val('no');
    $('#update_all').parent().hide();
    $('#no_show_warning').show();
    $('.sn-required').removeAttr('required');
  }
});

$(document).on('change', '#task_pathways_tracking_type', function(e) {
  var form_id = $(this).closest("form").attr('id')
  if ($(this).val() == 'submission') {
    $(`#${form_id} #attendanceTracking`).hide();
    $(`#${form_id} #hourTracking`).hide();
    $(`#${form_id} #attachmentTracking`).hide();
    $(`#${form_id} #courseTracking`).hide();
    $(`#${form_id} #submissionTracking`).show();
  } else if ($(this).val() == 'attendance') {
    $(`#${form_id} #submissionTracking`).hide();
    $(`#${form_id} #hourTracking`).hide();
    $(`#${form_id} #attachmentTracking`).hide();
    $(`#${form_id} #courseTracking`).hide();
    $(`#${form_id} #attendanceTracking`).show();
    $(`#${form_id} #trackingCount`).appendTo($(`#${form_id} #attendanceTracking`));
  } else if ($(this).val() == 'attachment') {
    $(`#${form_id} #attendanceTracking`).hide();
    $(`#${form_id} #hourTracking`).hide();
    $(`#${form_id} #submissionTracking`).hide();
    $(`#${form_id} #courseTracking`).hide();
    $(`#${form_id} #attachmentTracking`).show();
  } else if ($(this).val() == 'course') {
    $(`#${form_id} #attendanceTracking`).hide();
    $(`#${form_id} #hourTracking`).hide();
    $(`#${form_id} #submissionTracking`).hide();
    $(`#${form_id} #attachmentTracking`).hide();
    $(`#${form_id} #courseTracking`).show();
  } else {
    $(`#${form_id} #submissionTracking`).hide();
    $(`#${form_id} #hourTracking`).show();
    $(`#${form_id} #attendanceTracking`).hide();
    $(`#${form_id} #attachmentTracking`).hide();
    $(`#${form_id} #courseTracking`).hide();
    $(`#${form_id} #trackingCount`).appendTo($(`#${form_id} #hourTracking`));
  }
});

$(document).on('change', '#session_note_excused', function(e) {
  if ($(this).prop('checked')) {
    $('#excusedReasonSelector').show();
  } else {
    $('#excusedReasonSelector').hide().find('select').val(null);
  }
});

$(document).on('change', '#zoom_option #customSwitch2, #zoom_option #customSwitch3', function(e) {
  if ($(this).prop('checked')) {
    $('#zoomOptions').show();
  } else {
    $('#zoomOptions').hide();
  }
});

$(document).on('click', '#group-select-all', function(e) {
  var el;
  el = $('#selectGroups');
  if ($(this).is(':checked')) {
    el.find('option').prop('selected', true);
    return el.trigger('change');
  } else {
    el.find('option').prop('selected', false);
    return el.trigger('change');
  }
});

$(document).on('click', '#pathwaysManualTimeEntryButton', function(e) {
  e.preventDefault();
  $('#pathwaysManualTimeEntry').toggle();
});

$(document).on('click', '#pathwaysManualEventEntryButton', function(e) {
  e.preventDefault();
  $('#pathwaysManualEventEntry').toggle();
});

$(document).on('trix-blur', '#documents', function(e) {
  $('#userNoteForm').submit();
});

$(document).on('click', '#appointmentRequestBulkSchedule', function(e) {
  var i, ids, appointment_request_ids, appointment_request_id, request_course_ids, request_course_id, type_ids, apt_type, id, len, user, users;
  e.preventDefault();
  ids = [];
  type_ids = [];
  request_course_ids = [];
  appointment_request_ids = [];
  users = $("input[name='usersSelect']:checked");
  for (i = 0, len = users.length; i < len; i++) {
    user = users[i];
    id = $(user).data('id');
    apt_type = $(user).data('appointment-type-id');
    request_course_id = $(user).data('course-id');
    appointment_request_id = $(user).data('appointment-request-id');
    ids.push(id);
    type_ids.push(apt_type);
    request_course_ids.push(request_course_id);
    appointment_request_ids.push(appointment_request_id);
  }
  const uniqueSet = new Set(type_ids);
  const uniqueRequestCourseIds = new Set(request_course_ids);
  const uniqueRequestIds = new Set(appointment_request_ids);
  if (uniqueSet.size > 1) {
    alert("Multiple appointment types have been selected. Bulk schedule should be for the same type of appointment.");
  } else {
    Rails.ajax({
      url: "/appointments/new_in_bulk",
      type: "post",
      data: `student_ids=${ids}&redirect=/appointment_requests&appointment_type_id=${[...uniqueSet][0]}&course_id=${[...uniqueRequestCourseIds][0]}&appointment_request_ids=${[...uniqueRequestIds]}`,
      success: function(data) {
        console.log(data);
      },
      error: function(data) {}
    })
  };
});

$(document).on('click', '#newBulkCampaign', function(e) {
  var i, ids, id, len, user, users;
  e.preventDefault();
  ids = [];
  users = $("input[name='usersSelect']:checked");
  for (i = 0, len = users.length; i < len; i++) {
    user = users[i];
    id = $(user).data('id');
    ids.push(id);
  }
  Rails.ajax({
    url: "/appointment_campaigns/new_in_bulk",
    type: "post",
    data: `student_ids=${ids}`,
    success: function(data) {
      console.log(data);
    },
    error: function(data) {}
  })
});

$(document).on('click', '#newBulkFindATime', function(e) {
  var i, ids, id, len, user, users;
  e.preventDefault();
  ids = [];
  users = $("input[name='usersSelect']:checked");
  for (i = 0, len = users.length; i < len; i++) {
    user = users[i];
    id = $(user).data('id');
    ids.push(id);
  }
  Rails.ajax({
    url: "/appointments/magic_bulk_find",
    type: "post",
    data: `student_ids=${ids}`,
    success: function(data) {
      window.location = data.redirect;
    },
    error: function(data) {}
  })
});

$(document).on('change, click', '.toggle-div', function(e) {
  e.preventDefault();
  var element = $(this).data('toggle');
  $(element).toggle();
  var chevron_switch = $(this).data('chevron');
  if (chevron_switch) {
    if ($(element).is(':visible')) {
      $(this).html("<i class='fe fe-chevron-up'></i>");
    } else {
      $(this).html("<i class='fe fe-chevron-down'></i>");
    }
  }
});

$(document).on('shown.bs.modal', '#modal-edit-availability, #modal-edit-task', function (e) {
  $('.select2').select2();
  $('[data-toggle="flatpickr"]').flatpickr({
    altInput: true,
    altFormat: "F j, Y",
    dateFormat: "Y-m-d",
  });
  $('[data-toggle="flatpickr-datetime"]').flatpickr({
    enableTime: true,
    dateFormat: "Y-m-d H:i",
    altInput: true,
    altFormat: "Y-m-d G:i K",
  });
  $('[data-toggle="flatpickr-time"]').flatpickr({
    enableTime: true,
    noCalendar: true,
    time_24hr: false,
    dateFormat: "H:i K",
    altInput: true,
    altFormat: "G:i K"
  });
});

$(document).on('shown.bs.modal', '#modal-view-conflict', function (e) {
  $('[data-toggle="flatpickr"]').flatpickr({
    altInput: true,
    altFormat: "F j, Y",
    dateFormat: "Y-m-d",
  });
  $('[data-toggle="flatpickr-datetime"]').flatpickr({
    enableTime: true,
    dateFormat: "Y-m-d H:i",
    altInput: true,
    altFormat: "Y-m-d G:i K",
  });
  $('[data-toggle="flatpickr-time"]').flatpickr({
    enableTime: true,
    noCalendar: true,
    time_24hr: false,
    dateFormat: "H:i K",
    altInput: true,
    altFormat: "G:i K"
  });
});

$(document).on('shown.bs.modal', '#sidebarModalSearch', function (e) {
  $('#query').addClass('focus-visible').attr('data-focus-visible-added', '').focus();
});

$(document).on('keypress', function(e) {
  if ( e.ctrlKey && ( e.which === 47 ) ) {
    $("#sidebarModalSearch").modal();
  };
});

$(document).on('click', '#checkInUser', function(e) {
  e.preventDefault();
  var selectedId = $('#user_ids').select2('data');
  if (selectedId && selectedId[0] && selectedId[0].id) {
    var userId = selectedId[0].id
  } else {
    var userId = ''
  };
  var categoryId = $('#check_in_type_id').val();
  var locationId = $('#check_in_location_id').val();
  var checkInSubject = $('#check_in_course_subject').val();
  var product = $('#check_in_product').val();
  if (userId === '' || categoryId === '' || locationId === '') {
    Toastr.error('Please select a User, Location, and Category!');
    return
  }

  Rails.ajax({ 
    url: `/shared/users/check_in`,
    type: "post",
    data: `user_id=${userId}&check_in_location_id=${locationId}&check_in_type_id=${categoryId}&check_in_course_subject=${checkInSubject}&product=${product}`,
    success: function(data) {
      Toastr.success('User Checked In!');
      $('#user_ids').val(null).trigger('change');
      initSelectLists();
    },
    error: function(data) {
      Toastr.error('Failed to create new check in for this user');
      $('#user_ids').val(null).trigger('change');
      initSelectLists();
    }
  })
});

$(document).on('submit', '.bulk-action form', function(e) {
  var i, ids, id, len, user, users;
  e.preventDefault();
  ids = [];
  users = $("input[name='usersSelect']:checked:visible");
  for (i = 0, len = users.length; i < len; i++) {
    user = users[i];
    id = $(user).data('id')
    $('#removeBlueprintBulkAction, #studentsBulkAction, #addGroupsBulkAction, #newAppointmentBulkAction, #removeGroupsBulkAction, #removeUsersCampaignBulkAction, #reassignCheckInBulkAction, #taskCompleteBulkAction').append(`<input type='hidden' name='student_ids[]' value=${id} />`);
  }
  $(this)[0].submit();
});

$(document).on('click', '#studentBookingSubmit', function(e) {
  e.preventDefault()
  $('#studentSubmitSpinner').show();
  var user_id = $('#appointment_host_user_id').val();
  var start_time = $('#appointment_start_time').val();
  var end_time = $('#appointment_end_time').val();
  var start_date = $('#student_scheduled_start').val();
  var user_id = $('#current_user_id').val();
  var apt_type_id = $('#appointment_appointment_type_id').val();
  Rails.ajax({
    url: `/appointments/check_conflicts`,
    type: "post",
    data: `host_user_id=${user_id}&scheduled_start_time=${start_time}&scheduled_end_time=${end_time}&scheduled_date=${start_date}&request_user=${user_id}&appointment_type_id=${apt_type_id}`,
    success: function(data) {
      if (data.conflicts == true) {
        Toastr.error(data.conflict_message);
        $('#studentSubmitSpinner').hide();
        $('#scheduleCampaignButton').text('Submit');
        $('#scheduleCampaignButton').prop('disabled', false);
      } else {
        $('#new_appointment.validate-conflict-form').submit();
      }
    },
    error: function(data) {}
  })
});

$(document).on('submit', '.archive-bulk-action form', function(e) {
  var i, ids, id, len, user, users;
  e.preventDefault();
  ids = [];
  users = $("input[name='usersSelect']:checked");
  for (i = 0, len = users.length; i < len; i++) {
    user = users[i];
    id = $(user).data('appointment-request-id')
    $('#archiveRequestBulkAction').append(`<input type='hidden' name='student_ids[]' value=${id} />`);
  }
  $(this)[0].submit();
});

$(document).on('submit', '.delete-bulk-action form', function(e) {
  var i, ids, id, len, user, users;
  e.preventDefault();
  ids = [];
  users = $("input[name='usersSelect']:checked");
  for (i = 0, len = users.length; i < len; i++) {
    user = users[i];
    id = $(user).data('appointment-request-id')
    $('#deleteRequestBulkAction').append(`<input type='hidden' name='student_ids[]' value=${id} />`);
  }
  $(this)[0].submit();
});

$(document).on('submit', '.change-type-bulk-action form', function(e) {
  var i, ids, id, len, user, users;
  e.preventDefault();
  ids = [];
  users = $("input[name='usersSelect']:checked");
  for (i = 0, len = users.length; i < len; i++) {
    user = users[i];
    id = $(user).data('appointment-request-id')
    $('#changeRequestBulkAction').append(`<input type='hidden' name='student_ids[]' value=${id} />`);
  }
  $(this)[0].submit();
});

$(document).on('click', '#studentAppointmentHosts tr', function(e) {
  $('#studentAppointmentHosts tr').removeClass('table-primary selected');
  var user_id = $(this).data('id');
  var appointment_type_id = $('#appointment_type_id').text();
  var selected_date = $('#student_scheduled_start').val();
  var duration = $('#appointment_duration').text();
  var interval = $('#appointment_interval').text();
  var location_id = $("#student_schedule_location option:selected").val();
  $(this).addClass('table-primary selected');
  $('#appointment_host_user_id').val($(this).data('id'));
  if (selected_date != null && selected_date.length) {
    $('#timeSlotLoading').show();
    $('.alert.alert-light.instruction-reminder').hide();
    $('#availableSlots').empty();
    Rails.ajax({
      url: "/users/" + $(this).data('id') + "/time_slots.json",
      type: "get",
      data: `user_id=${user_id}&date=${selected_date}&duration=${duration}&interval=${interval}&location_id=${location_id}&appointment_type_id=${appointment_type_id}`,
      success: function(data) {
        $('#appointment_start_time, #appointment_end_time').val('');
        $('#confirmStudentSchedule').hide();
        $.each(data, function (i, val){
          $('#availableSlots').append(
            `<button type="button" class="slot-option btn btn-outline-primary m-2" data-start='${val.start}' data-end='${val.end}' data-date='${val.dateDesc}'>${val.start}</button>`
          );
        });
        $('#timeSlotLoading').hide();
        if (data.length > 0) {
          var location_preference = data[0]['location_preference'];
          if (location_preference == 'both') {
            $('.confirmStudentScheduleMessage').text('You have the option to choose how you would like to meet. Please select an option below:');
            $('#confirmStudentScheduleLocationChoice').show();
          } else if (location_preference == 'in_person') {
            $('.confirmStudentScheduleMessage').text(`This appointment will meet In-Person`);
            $('#confirmStudentScheduleLocationChoice').hide();
            $('#student_location_preference option[value=in_person').attr('selected', 'selected');
            $('#student_location_preference option[value=remote').attr('selected', false);
          } else {
            $('.confirmStudentScheduleMessage').text(`This appointment will meet Remotely`);
            $('#confirmStudentScheduleLocationChoice').hide();
            $('#student_location_preference option[value=remote').attr('selected', 'selected');
            $('#student_location_preference option[value=in_person').attr('selected', false);
          };
        };
      },
      error: function(data) {}
    })
  }
});

$(document).on('turbolinks:before-cache', function(){
  $('.selectpicker').selectpicker('destroy').addClass('selectpicker');
});

$(document).on('turbolinks:load', function(){
  Toastr.options.timeOut = 3000;
  Toastr.options.positionClass = 'toast-top-center mt-4';
  var clipboard = new ClipboardJS('.copy-btn');
  clipboard.on('success', function(e) {
    Toastr.success("Copied to clipboard!");
    e.clearSelection();
  });
  $("form.no-enter").on('keypress', e => {
    var element = e.target.nodeName.toLowerCase();
    if (element != 'textarea' && element != 'trix-editor') {
      if (e.keyCode == 13) {
        e.stopPropagation();
        e.preventDefault();
        return false;
      }
    };
  });
  var isFlash = $('#flashNotice');
  var isFlashError = $('#flashError');
  if (isFlash.length) {
    Toastr.success(isFlash.text());
  }
  if (isFlashError.length) {
    Toastr.error(isFlashError.text());
  }
  var advisorMenu = $('.advisor-sidebar');
  if (advisorMenu.length > 0 && advisorMenu.data('environment') == 'production') {
    Beamer.init();
  };
  Survey.StylesManager.applyTheme("bootstrap");
  var survey = $('#surveyContainer');
  if (survey.length) {
    var surveyId = $('#surveyId').val();
    var formId = $('#formId').val();
    var userId = $('#userId').val();
    console.log("getting ID")
    console.log(surveyId);
    var surveyJSON = { surveyId: surveyId}

    var survey = new Survey.Model(surveyJSON);
    $("#surveyContainer").Survey({
        model: survey,
        onComplete: function(survey) {
          survey.sendResult('6ac2fe9d-d408-43f2-a837-2b7df3915f8c');
          $.post(`/forms/${formId}/form_submissions`, {
            user_id: userId
          }, function(data) {
            Toastr.success('Submitted!');
          });
        }
    });
  };

  $(document).on('show.bs.tab', 'a[data-toggle="tab"]', function(e) {
    if (!e.target.classList.contains('no-url-update')) {
      var queryParams = new URLSearchParams(window.location.search);
      queryParams.set("tab", e.target.hash);
      window.history.replaceState('', '', "?"+queryParams.toString())
    };
  });

  $(document).on('click', '#campusLogin', function(e) {
    e.preventDefault();
    $('#campusLoginForm').show();
  });

  $(document).on('click', '#submitCampusLoginForm', function(e) {
    e.preventDefault();
    var school_id = $('#school_id').val().toLowerCase();
    var url = `https://app.retain.io/users/saml/sign_in?institution=${school_id}`
    $(location).attr('href',url);
  });

  $(document).on('click', '#edit-hours-per-week', function(e) {
    e.preventDefault();
    $('#hours-per-week-label').hide();
    $('#edit-hours-per-week-form').show();
  });

  $(document).on('click', '#update-hours-per-week', function(e) {
    e.preventDefault();
    var userId = $(this).data('id');
    var hours = $('#hours-per-week-value').val();
    Rails.ajax({
      url: `/tutors/${userId}/update_max_hours`,
      type: "post",
      data: `max_scheduled_weekly_hours=${hours}`,
      success: function(data) {
        Toastr.success('Updated!');
        $('#edit-hours-per-week-form').hide();
        $('#hours-per-week-label').text(hours).show();
      },
      error: function(data) {}
    })
  })

  $(document).on('change', '#student_pdf_export_select', function(e) {
    if (this.value == 'custom') {
      $('#student_pdf_export_date_range').show();
    } else {
      $('#student_pdf_export_date_range').hide();
    }
  });

  $(document).on('change', '#availability_duration_select', function(e) {
    if (this.value == 'custom') {
      $('.availability-date-option').show();
    } else {
      $('.availability-date-option').hide();
    }
  });

  $(document).on('change', '#appointment_campaign_default_appointment_frequency', function(e) {
    if (this.value == 'one-time') {
      $('#frequencyDate').hide();
    } else {
      $('#frequencyDate').show();
    }
  });

  $(document).on('change', '#appointment_campaign_location_id', function(e) {
    var user_id = $(this).data('user-id')
    Rails.ajax({
      url: "/locations/" + this.value + `/availability_check.json?user_id=${user_id}`,
      type: "get",
      success: function(data) {
        if (data.availability) {
          $('#campaignAvailabilityWarning').hide()
        } else {
          $('#campaignAvailabilityWarning').show()
        }
      },
      error: function(data) {

      }
    })
  });

  magicSchedulerCalendar();
  studentScheduler();
  appointmentTeamCalendar();
  initSelectLists();
  initWizard();
  $(document).on('change', '#appointment_repeats :checkbox', function() {
    $('#repeats_options_div').hide();
    if ($(this).prop('checked')) {
      $('#repeats_options_div').show();
      $('#repeats_options').show();
      $('#repeats_weekly_options').show();
    };
  });
  $('.select2').select2();
  $('.mask-phone').mask('(000) 000-0000');
  $(".select2-modal-add").select2({
    dropdownParent: $("#modalAddGroups"),
    tags: true
  });
  $(".select2-modal-remove").select2({
    dropdownParent: $("#modalRemoveGroups")
  });
  $('[data-toggle="tooltip"]').tooltip()
  $('.selectpicker').selectpicker({selectedTextFormat: 'count', actionsBox: true, style: 'btn-input-mask'});
  $('.select2Assignees').select2({
    placeholder: 'Search Users',
    minimumInputLength: 3,
    ajax: {
      url: '/users/search',
      dataType: 'json',
      data: function (params) {
        var query = {
          q: params.term,
          search_roles: $('.select2Assignees').data('roles')
        }
        return query;
      }
    }
  });
  $('.select2Users').select2({
    placeholder: 'Search User(s)',
    minimumInputLength: 3,
    ajax: {
      url: '/users/search',
      dataType: 'json',
      data: function (params) {
        var query = {
          q: params.term,
          search_roles: $('.select2Users').data('roles')
        }
        return query;
      }
    }
  });
  $('.select2Users2').select2({
    placeholder: 'Search User(s)',
    minimumInputLength: 3,
    ajax: {
      url: '/users/search',
      dataType: 'json',
      data: function (params) {
        var query = {
          q: params.term,
          search_roles: $('.select2Users2').data('roles')
        }
        return query;
      }
    }
  });
  $('[data-toggle="flatpickr"]').flatpickr({
    altInput: true,
    altFormat: "F j, Y",
    dateFormat: "Y-m-d"
  });
  $('[data-toggle="flatpickr-scheduler"]').flatpickr({
    altInput: true,
    altFormat: "F j, Y",
    dateFormat: "Y-m-d",
    inline: true,
    enable: [
      {
        from: $('[data-toggle="flatpickr-scheduler"]').data('start'),
        to: $('[data-toggle="flatpickr-scheduler"]').data('end')
      }
    ],
    onChange: function(selectedDates, dateStr, instance) {
      var slug = $('[data-toggle="flatpickr-scheduler"]').data('slug');
      var selected_date = dateStr;
      $('#schedule_appointment_date').val(selected_date);
      $('#studentLoadingSpinner').show();
      $('.alert.alert-light.instruction-reminder').hide();
      $('#availableSlots').empty();
      $('#appointment_start_time, #appointment_end_time').val('')
      $('#confirmStudentSchedule').hide()
      Rails.ajax({
        url: `/schedule/${slug}/schedule_time_slots.json`,
        type: "get",
        data: `date=${selected_date}`,
        success: function(data) {
          if (data.length > 0) {
            $('.no-times').hide();
            $.each(data, function (i, val){
              $('#availableSlots').append(
                `<button type="button" class="schedule-slot-option btn btn-outline-primary m-2" data-start='${val.start}' data-end='${val.end}' data-date='${val.dateDesc}'>${val.start}</button>`
              );
            });
            var location_preference = data[0]['location_preference'];
            if (location_preference == 'both') {
              $('.confirmStudentScheduleMessage').text('You have the option to choose how you would like to meet. Please select an option below:');
              $('#confirmStudentScheduleLocationChoice').show();
            } else if (location_preference == 'in_person') {
              $('.confirmStudentScheduleMessage').text(`This appointment will meet In-Person`);
              $('#confirmStudentScheduleLocationChoice').hide();
              $('#student_location_preference option[value=in_person').attr('selected', 'selected');
              $('#student_location_preference option[value=remote').attr('selected', false);
            } else {
              $('.confirmStudentScheduleMessage').text(`This appointment will meet online via Zoom`);
              $('#confirmStudentScheduleLocationChoice').hide();
              $('#student_location_preference option[value=remote').attr('selected', 'selected');
              $('#student_location_preference option[value=in_person').attr('selected', false);
            };
          } else {
            $('.alert.alert-light.instruction-reminder').hide();
            $('.no-times').show();
          };
          $('#studentLoadingSpinner').hide();
          // $('.alert.alert-light.instruction-reminder').hide();
          // $('#availableSlots').empty();
          // $('#appointment_start_time, #appointment_end_time').val('')
          // $('#confirmStudentSchedule').hide()
          // $.each(data, function (i, val){
          //   $('#availableSlots').append(
          //     `<button type="button" class="schedule-slot-option btn btn-outline-primary m-2" data-start='${val.start}' data-end='${val.end}' data-date='${val.dateDesc}'>${val.start}</button>`
          //   );
          // });
        },
        error: function(data) {}
      })
    }
  });
  $('[data-toggle="flatpickr-link-scheduler"]').flatpickr({
    altInput: true,
    altFormat: "F j, Y",
    dateFormat: "Y-m-d",
    inline: true,
    enable: [
      {
        from: $('[data-toggle="flatpickr-link-scheduler"]').data('start'),
        to: $('[data-toggle="flatpickr-link-scheduler"]').data('end')
      }
    ],
    onChange: function(selectedDates, dateStr, instance) {
      $('#availableSlots').empty();
      var slug = $('[data-toggle="flatpickr-link-scheduler"]').data('slug');
      var selected_date = dateStr;
      $('#schedule_appointment_start').val(selected_date);
      Rails.ajax({
        url: `/scheduling_links/${slug}/schedule_time_slots.json`,
        type: "get",
        data: `date=${selected_date}`,
        success: function(data) {
          console.log(data);
          $('.alert.alert-light.instruction-reminder').hide();
          $('#start_time, #end_time').val('');
          $('#confirmStudentSchedule').hide();
          if (data.length > 0) {
            $('.no-times').hide();
            $.each(data, function (i, val){
              $('#availableSlots').append(
                `<button type="button" class="link-schedule-slot-option btn btn-outline-primary m-2" data-start='${val.start}' data-end='${val.end}' data-date='${val.dateDesc}'>${val.start}</button>`
              );
            });
            var location_preference = data[0]['location_preference'];
            if (location_preference == 'both') {
              $('.confirmStudentScheduleMessage').text('You have the option to choose how you would like to meet. Please select an option below:');
              $('#confirmStudentScheduleLocationChoice').show();
            } else if (location_preference == 'in_person') {
              $('.confirmStudentScheduleMessage').text(`This appointment will meet In-Person`);
              $('#confirmStudentScheduleLocationChoice').hide();
              $('#student_location_preference option[value=in_person').attr('selected', 'selected');
              $('#student_location_preference option[value=remote').attr('selected', false);
            } else {
              $('.confirmStudentScheduleMessage').text(`This appointment will meet online via Zoom`);
              $('#confirmStudentScheduleLocationChoice').hide();
              $('#student_location_preference option[value=remote').attr('selected', 'selected');
              $('#student_location_preference option[value=in_person').attr('selected', false);
            };
          } else {
            $('.no-times').show();
          }
        },
        error: function(data) {}
      })
    }
  });
  $('[data-toggle="flatpickr-datetime"]').flatpickr({
    enableTime: true,
    dateFormat: "Y-m-d H:i",
    altInput: true,
    altFormat: "Y-m-d G:i K"
  });
  $('[data-toggle="flatpickr-time"]').flatpickr({
    enableTime: true,
    noCalendar: true,
    time_24hr: false,
    dateFormat: "H:i K",
    altInput: true,
    altFormat: "G:i K"
  });
  $('[data-toggle="flatpickr-time-15"]').flatpickr({
    enableTime: true,
    noCalendar: true,
    time_24hr: false,
    dateFormat: "H:i K",
    minuteIncrement: 15,
    altInput: true,
    altFormat: "G:i K"
  });
  $('[data-toggle="flatpickr-range"]').flatpickr({
    mode: 'range',
    dateFormat: "Y-m-d",
    defaultDate: [$('#by_date_start_date').val(), $('#by_date_end_date').val()],
    onClose: function(selectedDates, dateStr, instance){
      console.log('closed');
      $('#by_date_start_date').val(moment(selectedDates[0]).format('YYYY-MM-DD'))
      $('#by_date_end_date').val(moment(selectedDates[1]).format('YYYY-MM-DD'))
    }
  });
  $('[data-toggle="flatpickr-required"]').flatpickr({
    altInput: true,
    altFormat: "Y-m-d h:i K",
    dateFormat: "Y-m-d H:i",
    enableTime: true,
    time_24hr: false,
    required: true,
    allowInput: true,
    onOpen: function(selectedDates, dateStr, instance) {
      $(instance.altInput).prop('readonly', true);
    },
    onClose: function(selectedDates, dateStr, instance) {
      $(instance.altInput).prop('readonly', false);
    }
  });

  $(document).on('change', '#blueprint_selector_for_expiration', function(e) {
    var blueprint_id = $(this).val();
    var requires_expiration = $('#blueprints_requiring_expiration').val();
    if (requires_expiration.includes(blueprint_id)) {
      $('#expired_at_group').addClass('required');
      $('#expired_at_group').children('input.form-control').attr('required', 'required');
    }
    else {
      $('#blueprint_expired_at').removeAttr('required');
      $('#expired_at_group').children('input.form-control').removeAttr('required');
    }
  });

  /**
   * Check for a sortable table. Instantiate a new List if found. Uses a wildcard search to query all ids that begin
   * with 'sortableList'. If your page has more than one table, prepend their ids with additional detail.
   *
   * e.g. sortableList_students, sortableList_advisors, etc.
   */
  const sortableLists = document.querySelectorAll('[id^="sortableList"]');

  if (sortableLists.length > 0) {
    sortableLists.forEach(sortableList => {
      const tableId = sortableList.id;
      const headerLinks = sortableList.querySelectorAll('table th a');

      headerLinks.forEach(link => {
        link.addEventListener('click', (event) => {
          event.preventDefault();
        });
      });

      // Concatenation is used because it appears the List library requires strings; variables won't work here.
      new List(`${tableId}`, $(`#${tableId}`).data('options'));
    });
  }
  /**
   *
   */

  $('#setPin').click(function (e) {
    e.preventDefault();
    var user_id;
    user_id = $(this).data('id');
    $(this).removeClass('btn-white').addClass('btn-dark');
    $.post('/users/' + user_id + '/pin', {
      user_id: user_id
    }, function(data) {
      console.log(data);
    });
  });

  $('#removePin').click(function (e) {
    e.preventDefault();
    var user_id;
    user_id = $(this).data('id');
    $(this).removeClass('btn-dark').addClass('btn-white');
    $.post('/users/' + user_id + '/unpin', {
      user_id: user_id
    }, function(data) {
      console.log(data);
    });
  });

  $(document).on('keyup', '#searchStudents', function(e) {
    var name;
    name = $("#searchStudents").val();
    $(".student-row").hide();
    return $(".student-row .user-name").each(function() {
      var row;
      row = $(this);
      if (row.html().toLowerCase().indexOf(name.toLowerCase()) !== -1) {
        return row.parents(".student-row").show();
      }
    });
  });

  $(document).on('keyup', '#searchPartners', function(e) {
    var name;
    name = $("#searchPartners").val();
    $(".partner-row").hide();
    return $(".partner-row .partner-name").each(function() {
      var row;
      row = $(this);
      if (row.html().toLowerCase().indexOf(name.toLowerCase()) !== -1) {
        return row.parents(".partner-row").show();
      }
    });
  });

  $(document).on('keyup', '#searchEquipment', function(e) {
    var name;
    name = $("#searchEquipment").val();
    $(".equipment-row").hide();
    return $(".equipment-row .equipment-name").each(function() {
      var row;
      row = $(this);
      if (row.html().toLowerCase().indexOf(name.toLowerCase()) !== -1) {
        return row.parents(".equipment-row").show();
      }
    });
  });

  $(document).on('keyup', '#searchHosts', function(e) {
    var name;
    name = $("#searchHosts").val();
    $("#appointmentHosts .host-row").hide();
    return $("#appointmentHosts .host-row .host-name").each(function() {
      var row;
      row = $(this);
      if (row.html().toLowerCase().indexOf(name.toLowerCase()) !== -1) {
        return row.parents(".host-row").show();
      }
    });
  });

  function updateStudentHostAvailability(data) {
    $.each(data.available, function (i, val){
      $(`#studentAppointmentHosts tr[data-id=${val}] .available-status span`).removeClass('badge-soft-secondary');
      $(`#studentAppointmentHosts tr[data-id=${val}] .available-status span`).addClass('badge-soft-success');
      $(`#studentAppointmentHosts tr[data-id=${val}]`).show();
    });
    $.each(data.not_available, function (i, val){
      $(`#studentAppointmentHosts tr[data-id=${val}] .available-status span`).removeClass('badge-soft-success');
      $(`#studentAppointmentHosts tr[data-id=${val}] .available-status span`).addClass('badge-soft-secondary');
      $(`#studentAppointmentHosts tr[data-id=${val}]`).hide();
    });
    $('#studentHostSpinner').hide();
    $('#studentAppointmentHosts').show();
    if (data.available.length > 0) {
      $('#noHostsAvailable').hide();
    } else {
      $('#noHostsAvailable').show();
    };
  }

  $('#student_schedule_location').on('change', function(e) {
    $('#studentHostSpinner').show();
    var location_id = $('#student_schedule_location option:selected').val();
    var course_subject = $('#student_schedule_course_subject option:selected').text();
    var appointment_type_id = $('#appointment_appointment_type_id').val();
    var purpose = $('#appointment_purpose').text();
    $('#availableSlots').empty();
    var user_ids = [];
    var course_id = $("#appointment_course_id").text();
    var start_date = $("#student_scheduled_start").val();
    if (location_id && (purpose != 'course' || course_subject)) {
      $('#studentScheduleNoLocation').hide();
      $('#studentScheduleDetails').show();
      Rails.ajax({
        url: "/locations/" + location_id + "/available_users.json",
        type: "get",
        data: `appointment_type_id=${appointment_type_id}&course_subject=${course_subject}&course_id=${course_id}`,
        success: function(data) {
          if (data['data'].length > 0) {
            $('#noHostsAvailable').hide();
          } else {
            $('#noHostsAvailable').show();
          };
          $('#studentAppointmentHosts tbody').empty();
          $.each(data['data'], function (i, val){
            $('#studentAppointmentHosts tbody').append(
              `<tr data-id='${val.id}' class=''><td style='padding: 4px;'>${val.attributes.full_name}</td><td style='padding: 4px;' class='available-status'><span class='badge badge-soft-secondary'>available</span></td></tr>`
            );
          });
          if (start_date) {
            $('#studentAppointmentHosts tr').each(function(){
              var $this = $(this);
              user_ids.push([ $this.data('id') ]);
            });
            Rails.ajax({
              url: "/appointments/retrieve_availability",
              type: "post",
              data: `user_ids=${user_ids}&by_course_id=${course_id}&by_location_id=${location_id}&by_start_date=${start_date}&student_booking=1`,
              success: function(data) {
                updateStudentHostAvailability(data);
              },
              error: function(data) {}
            });
          } else {
            $('#studentHostSpinner').hide();
          };
        },
        error: function(data) {}
      })
    } else {
      $('#studentScheduleNoLocation').show();
      $('#studentScheduleDetails').hide();
      $('#studentHostSpinner').hide();
    };
  });

  $('#student_schedule_course_subject').on('change', function(e) {
    $('#studentHostSpinner').show();
    var location_id = $('#student_schedule_location option:selected').val();
    var course_subject = $('#student_schedule_course_subject option:selected').text();
    var appointment_type_id = $('#appointment_appointment_type_id').val();
    var purpose = $('#appointment_purpose').text();
    $('#availableSlots').empty();
    var user_ids = [];
    var course_id = $("#appointment_course_id").text();
    var start_date = $("#student_scheduled_start").val();
    if (location_id && (purpose != 'course' || course_subject)) {
      $('#studentScheduleNoLocation').hide();
      $('#studentScheduleDetails').show();
      Rails.ajax({
        url: "/locations/" + location_id + "/available_users.json",
        type: "get",
        data: `appointment_type_id=${appointment_type_id}&course_subject=${course_subject}&course_id=${course_id}`,
        success: function(data) {
          if (data['data'].length > 0) {
            $('#noHostsAvailable').hide();
          } else {
            $('#noHostsAvailable').show();
          };
          $('#studentAppointmentHosts tbody').empty();
          $.each(data['data'], function (i, val){
            $('#studentAppointmentHosts tbody').append(
              `<tr data-id='${val.id}' class=''><td style='padding: 4px;'>${val.attributes.full_name}</td><td style='padding: 4px;' class='available-status'><span class='badge badge-soft-secondary'>available</span></td></tr>`
            );
          });
          if (start_date) {
            $('#studentAppointmentHosts tr').each(function(){
              var $this = $(this);
              user_ids.push([ $this.data('id') ]);
            });
            Rails.ajax({
              url: "/appointments/retrieve_availability",
              type: "post",
              data: `user_ids=${user_ids}&by_course_id=${course_id}&by_location_id=${location_id}&by_start_date=${start_date}&student_booking=1`,
              success: function(data) {
                updateStudentHostAvailability(data);
              },
              error: function(data) {}
            });
          } else {
            $('#studentHostSpinner').hide();
          };
        },
        error: function(data) {}
      })
    } else {
      $('#studentScheduleNoLocation').show();
      $('#studentScheduleDetails').hide();
    };
  });

  $('#student_schedule_reason').on('change', function(e) {
    $('#studentHostSpinner').show();
    var location_id = $('#student_schedule_location option:selected').val();
    var reason_id = $('#student_schedule_reason option:selected').val();
    var reason_text = $('#student_schedule_reason option:selected').text();
    var appointment_type_id = $('#appointment_appointment_type_id').val();
    var course_subject = $('#student_schedule_course_subject option:selected').text();
    var purpose = $('#appointment_purpose').text();
    $('#availableSlots').empty();
    var user_ids = [];
    var course_id = $("#appointment_course_id").text();
    var start_date = $("#student_scheduled_start").val();
    if (reason_text == 'Other') {
      $('#studentScheduleNotes').show();
    };
    if (location_id && (purpose != 'course' || course_subject)) {
      $('#studentScheduleNoLocation').hide();
      $('#studentScheduleDetails').show();
      Rails.ajax({
        url: "/locations/" + location_id + "/available_users.json",
        type: "get",
        data: `appointment_type_id=${appointment_type_id}&location_id=${location_id}&course_subject=${course_subject}&course_id=${course_id}&appointment_reason_id=${reason_id}`,
        success: function(data) {
          if (data['data'].length > 0) {
            $('#noHostsAvailable').hide();
          } else {
            $('#noHostsAvailable').show();
          };
          $('#studentAppointmentHosts tbody').empty();
          $.each(data['data'], function (i, val){
            $('#studentAppointmentHosts tbody').append(
              `<tr data-id='${val.id}' class=''><td style='padding: 4px;'>${val.attributes.full_name}</td><td style='padding: 4px;' class='available-status'><span class='badge badge-soft-secondary'>available</span></td></tr>`
            );
          });
          if (start_date) {
            $('#studentAppointmentHosts tr').each(function(){
              var $this = $(this);
              user_ids.push([ $this.data('id') ]);
            });
            Rails.ajax({
              url: "/appointments/retrieve_availability",
              type: "post",
              data: `user_ids=${user_ids}&by_course_id=${course_id}&by_location_id=${location_id}&by_start_date=${start_date}&student_booking=1`,
              success: function(data) {
                updateStudentHostAvailability(data);
              },
              error: function(data) {}
            });
          } else {
            $('#studentHostSpinner').hide();
          };
        },
        error: function(data) {}
      })
    } else {
      $('#studentScheduleNoLocation').show();
      $('#studentScheduleDetails').hide();
    };
  });

  $('#student_scheduled_start').on('change', function(e) {
    $('#studentHostSpinner').show();
    $('#studentAppointmentHosts').hide();
    var user_ids = [];
    var course_id = $("#appointment_course_id").text();
    var location_id = $("#student_schedule_location option:selected").val();
    var appointment_type_id = $('#appointment_type_id').text();
    var start_date = $("#student_scheduled_start").val();
    var duration = $('#appointment_duration').text();
    var interval = $('#appointment_interval').text();
    var already_selected_host = $('#studentAppointmentHosts tr.selected');
    $('#studentAppointmentHosts tr').each(function(){
      var $this = $(this);
      user_ids.push([ $this.data('id') ]);
    });
    Rails.ajax({
      url: "/appointments/retrieve_availability",
      type: "post",
      data: `user_ids=${user_ids}&by_course_id=${course_id}&by_location_id=${location_id}&by_start_date=${start_date}&student_booking=1`,
      success: function(data) {
        updateStudentHostAvailability(data);
      },
      error: function(data) {}
    })
    if (already_selected_host != null && already_selected_host.length) {
      var selected_host_id = $(already_selected_host).data('id');
      $('#availableSlots').empty();
      $('#timeSlotLoading').show();
      Rails.ajax({
        url: "/users/" + selected_host_id + "/time_slots.json",
        type: "get",
        data: `user_id=${selected_host_id}&date=${start_date}&duration=${duration}&interval=${interval}&location_id=${location_id}&appointment_type_id=${appointment_type_id}`,
        success: function(data) {
          $('.alert.alert-light.instruction-reminder').hide();
          $('#appointment_start_time, #appointment_end_time').val('')
          $('#confirmStudentSchedule').hide()
          $.each(data, function (i, val){
            $('#availableSlots').append(
              `<button type="button" class="slot-option btn btn-outline-primary m-2" data-start='${val.start}' data-end='${val.end}' data-date='${val.dateDesc}'>${val.start}</button>`
            );
          });
          $('#timeSlotLoading').hide();
          if (data.length > 0) {
            var location_preference = data[0]['location_preference'];
            if (location_preference == 'both') {
              $('.confirmStudentScheduleMessage').text('You have the option to choose how you would like to meet. Please select an option below:');
              $('#confirmStudentScheduleLocationChoice').show();
            } else if (location_preference == 'in_person') {
              $('.confirmStudentScheduleMessage').text(`This appointment will meet In-Person`);
              $('#confirmStudentScheduleLocationChoice').hide();
              $('#student_location_preference option[value=in_person').attr('selected', 'selected');
            } else {
              $('.confirmStudentScheduleMessage').text(`This appointment will meet Remotely`);
              $('#confirmStudentScheduleLocationChoice').hide();
              $('#student_location_preference option[value=remote').attr('selected', 'selected');
            };
          };
        },
        error: function(data) {}
      })
    }
  });
});

$(document).on('click', '.slot-option', function(e) {
  var has_form = $('#formStep');
  $('.slot-option').removeClass('btn-primary').addClass('btn-outline-primary');
  var start = $(this).data('start');
  var end = $(this).data('end');
  var dateDesc = $(this).data('date');
  $(this).addClass('btn-primary').removeClass('btn-outline-primary');
  $('#appointment_start_time').val(start);
  $('#appointment_end_time').val(end);
  $('.confirmStudentScheduleDetails').html(`<b>Date:</b> ${dateDesc} <b>Time:</b> ${start}-${end}`);
  if (has_form.length) {
    $('#formStep .card-body').show();
  } else {
    $('#confirmStudentSchedule').show();
  }
});

$(document).on('click', '#formStep .card-body button', function(e) {
  $('#formDetails').show();
  $('#formStep .card-body button').hide();
  $('#studentScheduleDetails #dateSelection').hide();
  $('#studentScheduleDetails #hostSelection').hide();
  $('#studentScheduleDetails #timeSelection').hide();
  $('#confirmStudentSchedule').show();
});

$(document).on('click', '#goBack', function(e) {
  $('#formDetails').hide();
  $('#formStep .card-body button').show();
  $('#studentScheduleDetails #dateSelection').show();
  $('#studentScheduleDetails #hostSelection').show();
  $('#studentScheduleDetails #timeSelection').show();
  $('#confirmStudentSchedule').hide();
});

$(document).on('submit', '#attendeeSearch', function (e) {
  e.preventDefault();
  $('#attendeeSearchResults #attendeeSearchResultsInner').empty();
  var group_id = $('#attendeeSearch #group_id').val();
  $.get('/users/search.json', {
    q: $('#attendeeSearch #searchAttendee').val(),
    search_roles: 'student'
  }, function(data) {
    $.each(data.results, function (i, val){
      $('#attendeeSearchResults #attendeeSearchResultsInner').append(
        `<a href='/appointment_schedules/${group_id}/add_user?user_id=${val.id}' class='list-group-item px-0' data-confirm='Are you sure you want to add this attendee to all future appointments?'><div class='row align-items-center'><div class='col-auto'><i class='fe fe-plus-circle'></i></div><div class='col ml-n2'><h4 class='text-body mb-1 name'>${val.text}</h4></div></div></a>`
      );
    });
  });
});

$(document).on('click', '#scheduleLinkButton', function(e) {
  e.preventDefault();
  $('#linkSubmitSpinner').show();
  $('#scheduleLinkButton').text('Submitting...');
  $('#scheduleLinkButton').prop('disabled', true);
  var user_id = $('#appointment_host_user_id').val();
  var start_time = $('#start_time').val();
  var end_time = $('#end_time').val();
  var start_date = $('#schedule_appointment_start').val();
  Rails.ajax({
    url: `/appointments/check_conflicts`,
    type: "post",
    data: `host_user_id=${user_id}&scheduled_start_time=${start_time}&scheduled_end_time=${end_time}&scheduled_date=${start_date}`,
    success: function(data) {
      if (data.conflicts == true) {
        Toastr.error('Oops! It looks like that time slot is now taken. Please make another selection.');
        $('#linkSubmitSpinner').hide();
        $('#scheduleLinkButton').text('Schedule Appointment');
        $('#scheduleLinkButton').prop('disabled', false);
      } else {
        $('#new_appointment').submit();
      }
    },
    error: function(data) {}
  })
});

$(document).on('click', '#scheduleCampaignButton', function(e) {
  $('#studentSubmitSpinner').show();
  $(this).text('Submitting...');
  $(this).prop('disabled', true);
  var slug = $(this).data('slug');
  var start_time = $('#schedule_appointment_start_time').val();
  var end_time = $('#schedule_appointment_end_time').val();
  var date = $('#schedule_appointment_date').val();
  var comments = $('#schedule_comments').val();
  var user_id = $('#schedule_appointment_host').val();
  var location_preference = $('#student_location_preference').val();
  Rails.ajax({
    url: `/appointment_campaign_recipients/check_conflicts`,
    type: "post",
    data: `host_user_id=${user_id}&scheduled_start_time=${start_time}&scheduled_end_time=${end_time}&scheduled_date=${date}&slug=${slug}`,
    success: function(data) {
      if (data.conflicts == true) {
        Toastr.error('Oops! It looks like that time slot is now taken. Please make another selection.');
        $('#studentSubmitSpinner').hide();
        $('#scheduleCampaignButton').text('Submit');
        $('#scheduleCampaignButton').prop('disabled', false);
      } else {
        Rails.ajax({
          url: "/appointment_campaign_recipients/create_appointment",
          type: "post",
          data: `slug=${slug}&date=${date}&start_time=${start_time}&end_time=${end_time}&comments=${comments}&student_location_preference=${location_preference}`,
          success: function(data) {
            $('#appointmentCampaignConfirmation').show();
            $('#appointmentCampaignDetails').hide();
          },
          error: function(data) {
            $('#scheduleCampaignButton').hide();
            $('#scheduleCampaignButton').text('Schedule');
            $('#scheduleCampaignButton').prop('disabled', false);
            $('#scheduleCampaignErrors .alert-danger').text(data.error);
            $('#scheduleCampaignErrors').show();
          }
        })
      }
    },
    error: function(data) {}
  })
});

$(document).on('click', '#advancedPulseSearchSubmit', function(event) {
  event.preventDefault();

  const searchData = $('#advancedSearchIds').val();

  $('#advancedSearchSpinner').css('display', 'flex');
  $('#advancedSearchTableResults').empty();

  Rails.ajax({
    url: '/users/bulk_search',
    type: 'post',
    data: `user_ids=${encodeURIComponent(searchData)}&product=pulse`,
    success: function(data) {
      $('#advancedSearchSpinner').hide();
      initSelectLists();
    },
    error: function(data) {}
  });
});

$(document).on('click', '#advancedPathwaysSearchSubmit', function(event) {
  event.preventDefault();

  const searchData = $('#advancedSearchIds').val();

  $('#advancedSearchSpinner').css('display', 'flex');
  $('#advancedSearchTableResults').empty();

  Rails.ajax({
    url: '/users/bulk_search',
    type: 'post',
    data: `user_ids=${encodeURIComponent(searchData)}&product=pathways`,
    success: function(data) {
      $('#advancedSearchSpinner').hide();
      initSelectLists();
    },
    error: function(data) {}
  });
});

$(document).on('click', '#advancedSearchSubmit', function(e) {
  e.preventDefault();
  var searchData = $('#advancedSearchIds').val();
  $('#advancedSearchSpinner').show();
  $('#advancedSearchTableResults').empty();
  Rails.ajax({
    url: "/users/bulk_search",
    type: "post",
    data: `user_ids=${searchData}`,
    success: function(data) {
      $('#advancedSearchSpinner').hide();
      initSelectLists();
    },
    error: function(data) {}
  })
})

$(document).on('click', '#viewAppointmentCancelButton', function(e) {
  e.preventDefault();
  $('#viewAppointmentCancel').toggle();
  $('#viewAppointmentDetails').toggle();
  $(this).text( $(this).text() == 'Cancel Appointment' ? 'Go Back' : 'Cancel Appointment' );
});

$(document).on('click', '#magicScheduleAll', function(e) {
  e.preventDefault();
  $('#magicConflictScheduleAll').toggle();
  $('#magicConflictDetails').toggle();
  $(this).text( $(this).text() == 'Schedule All' ? 'Go Back' : 'Schedule All' );
});

$(document).on('click', '.viewAppointmentStudentCancelButton', function(e) {
  e.preventDefault();
  $('#viewAppointmentCancelAttendees :checkbox').removeAttr('checked');
  $(`#appointment_cancel_users_${$(this).data('id')}`).attr('checked', true);
  $('#viewAppointmentCancel').toggle();
  $('#viewAppointmentDetails').toggle();
  $('#viewAppointmentCancelAttendees').toggle();
});

$(document).on('click', '.viewAppointmentDeleteButton', function(e) {
  e.preventDefault();
  $('#viewAppointmentDelete').toggle();
  $('#viewAppointmentCancel').hide();
  $('#viewAppointmentAddAttendance').hide();
  $('#viewAppointmentDetails').toggle();
});

$(document).on('click', '.viewAppointmentAddAttendanceButton', function(e) {
  e.preventDefault();
  $('#viewAppointmentAddAttendance').toggle();
  $('#viewAppointmentDelete').hide();
  $('#viewAppointmentCancel').hide();
  $('#viewAppointmentDetails').toggle();
});

$(document).on('click', '#viewAppointmentReinstateButton', function(e) {
  e.preventDefault();
  $('#viewAppointmentReinstate').toggle();
  $('#viewAppointmentDetails').toggle();
  $('#viewAppointmentDelete').hide();
});

$(document).on('click', '#addToCurrentTerm', function(e) {
  e.preventDefault();
  var ids = [];
  var i;
  var len;
  var users = $("input[name='usersSelect']:checked");
  var role_name = $(this).data('role');
  for (i = 0, len = users.length; i < len; i++) {
    var user = users[i];
    ids.push($(user).data('id'));
  }
  Rails.ajax({
    url: `/users/add_role_for_current_term.js`,
    type: "post",
    data: `user_ids=${ids}&role=${role_name}`,
    success: function(data) {
      Toastr.success('Added to current term!');
    },
    error: function(data) {}
  })
});

$(document).on('click', '#addToNextTerm', function(e) {
  e.preventDefault();
  var ids = [];
  var i;
  var len;
  var users = $("input[name='usersSelect']:checked");
  var role_name = $(this).data('role');
  for (i = 0, len = users.length; i < len; i++) {
    var user = users[i];
    ids.push($(user).data('id'));
  }
  Rails.ajax({
    url: `/users/add_role_for_next_term.js`,
    type: "post",
    data: `user_ids=${ids}&role=${role_name}`,
    success: function(data) {
      Toastr.success('Added to future term!');
    },
    error: function(data) {}
  })
});

$(document).on('click', '#submit_progress_report', function(e) {
  var prevent = $('#oduValidation');
  if (prevent.length > 0) {
    e.preventDefault();
    var triggerValues = ["C-", "D+", "D", "D-", "F", "F*"]
    var missingReasons = []
    var selectedGrades = $('.grade-option').filter(function() {
      if (triggerValues.includes($(this).val())) {
        var grade_val = $(this).val();
        var reason_elem = $(this).parent().parent().children('.survey-option-alert_reasons').children('.select2')[0];
        var alert_val = $(reason_elem).select2('data');
        console.log(grade_val);
        console.log(alert_val);
        if (alert_val.length > 0) {
          return false;
        } else {
          missingReasons.push(alert_val.length)
          return true;
        }
      } else {
        return false;
      }
    });

    if (missingReasons.length > 0) {
      alert("Please provide an Alert Reason for any student with a C- grade or below.")
    } else {
      $('#progress_report_form').submit();
    }
  };
})

$(document).on('click', '.schedule-slot-option', function(e) {
  $('.schedule-slot-option').removeClass('btn-primary').addClass('btn-outline-primary');
  var start = $(this).data('start');
  var end = $(this).data('end');
  var dateDesc = $(this).data('date');
  $(this).addClass('btn-primary').removeClass('btn-outline-primary');
  $('#schedule_appointment_start_time').val(start);
  $('#schedule_appointment_end_time').val(end);
  $('#confirmStudentSchedule').show();
  $('.confirmStudentScheduleDetails').html(`<b>Date:</b> ${dateDesc} <b>Time:</b> ${start}-${end}`);
});

$(document).on('click', '.link-schedule-slot-option', function(e) {
  $('.link-schedule-slot-option').removeClass('btn-primary').addClass('btn-outline-primary');
  var start = $(this).data('start');
  var end = $(this).data('end');
  var dateDesc = $(this).data('date');
  $(this).addClass('btn-primary').removeClass('btn-outline-primary');
  $('#start_time').val(start);
  $('#end_time').val(end);
  $('#confirmStudentSchedule').show();
  $('.confirmStudentScheduleDetails').html(`<b>Date:</b> ${dateDesc} <br /><b>Time:</b> ${start} - ${end}`);
});

import "controllers"
// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
